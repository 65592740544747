






















































import { apiModify } from '@/api';
import DataTable from '@/components/Admin/DataTable.vue';
import { API } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    DataTable,
  },
})
export default class extends Vue {
  @Prop({ type: String, required: true }) readonly loginURL!: string;
  authorisedChannels: API.AuthorisedChannels.Channels.GET[] = []; // Only stores the current page.
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifyChannel: API.AuthorisedChannels.Channels.GET | null = null; // Chan to be modified/deleted.
  options = {
    apiName: 'authorisedChannels',
    socketName: 'authorisedChannelModified',
    idStr: 'id',
    headers: [
      { text: 'Name', value: 'name' },
      { text: '', value: 'invalid', width: '1%', align: 'end' },
      { text: '', value: 'markers', width: '1%', align: 'end' },
      { text: '', value: 'commercials', width: '1%', align: 'end' },
    ],
    hideEdit: true,
    hideAdd: true,
  };

  get redirectURL(): string {
    return encodeURIComponent(
      `${window.location.origin}${process.env.BASE_URL}admin/authorisedchannels`,
    );
  }

  async changeMarker(id: string, bool: boolean): Promise<void> {
    try {
      const { data } = await apiModify('authorisedChannels', id, { markers: bool });
      const index = this.authorisedChannels.findIndex((c) => c.id === id);
      if (index >= 0) this.authorisedChannels[index] = data;
    } catch (err) {
      // error
    }
  }

  async changeCommercial(id: string, bool: boolean): Promise<void> {
    try {
      const { data } = await apiModify('authorisedChannels', id, { commercials: bool });
      const index = this.authorisedChannels.findIndex((c) => c.id === id);
      if (index >= 0) this.authorisedChannels[index] = data;
    } catch (err) {
      // error
    }
  }
}
