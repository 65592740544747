var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"items":_vm.authorisedChannels,"tableOptions":_vm.tableOptions,"modifyItem":_vm.modifyChannel,"options":_vm.options,"no-data-text":"No channels have been authorised."},on:{"update:items":function($event){_vm.authorisedChannels=$event},"update:tableOptions":function($event){_vm.tableOptions=$event},"update:table-options":function($event){_vm.tableOptions=$event},"update:modifyItem":function($event){_vm.modifyChannel=$event},"update:modify-item":function($event){_vm.modifyChannel=$event}},scopedSlots:_vm._u([{key:"deleteConfirmationTitle",fn:function(){return [_vm._v(" Channel Delete Confirmation: "+_vm._s(_vm.modifyChannel.name)+" ")]},proxy:true},{key:"deleteConfirmationText",fn:function(){return [_vm._v(" Are you sure you want to delete this channel? ")]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"font-italic"},[_vm._v("("+_vm._s(item.id)+")")])]}},{key:"item.invalid",fn:function(ref){
var item = ref.item;
return [(item.invalid)?[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-circle")])]:_vm._e()]}},{key:"item.markers",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"input-value":item.markers,"label":"Markers","hide-details":""},on:{"change":function($event){return _vm.changeMarker(item.id, $event)}}})]}},{key:"item.commercials",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"input-value":item.commercials,"label":"Commercials","hide-details":""},on:{"change":function($event){return _vm.changeCommercial(item.id, $event)}}})]}},{key:"footer.prepend",fn:function(){return [_c('v-btn',{attrs:{"href":(_vm.loginURL + "?redirect=" + _vm.redirectURL),"color":"purple darken-2"}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-twitch")]),_vm._v(" Authorise New Channel ")],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }