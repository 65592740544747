














import { serverURL } from '@/util';
import { Vue, Component } from 'vue-property-decorator';
import TableComponent from './components/Table.vue';

@Component({
  components: {
    TableComponent,
  },
})
export default class extends Vue {
  err = false;

  created(): void {
    if (this.$route.query.err) {
      this.err = true;
    }
  }

  get loginURL(): string {
    return `${serverURL()}authorised_channels/login`;
  }
}
